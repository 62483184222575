import { FeatureDetector } from 'eyeson';

const inIframe = FeatureDetector.inIframe();
const isSafari = FeatureDetector.isSafari();

const minimumChromiumVersion = (minimumVersion) => {
  return (
    Reflect.has(navigator, 'userAgentData') &&
    navigator.userAgentData.brands.some(
      ({ brand, version }) =>
        brand === 'Chromium' && Number(version) >= minimumVersion
    )
  );
};

const supports = {
  ClipboardAPI:
    !!navigator.clipboard &&
    typeof navigator.clipboard.writeText === 'function' &&
    !inIframe,
  KeyboardLockAPI: false,
  // KeyboardLockAPI:
  //   !!navigator.keyboard &&
  //   typeof navigator.keyboard.lock === 'function' &&
  //   !inIframe,
  PiPConferenceControls:
    navigator.mediaSession &&
    typeof navigator.mediaSession.setCameraActive === 'function',
  playCanvasStreamBug: isSafari && FeatureDetector.browserVersion() < 16,
  YoutubePopup:
    navigator.mediaDevices &&
    navigator.mediaDevices.getSupportedConstraints &&
    navigator.mediaDevices.getSupportedConstraints()
      .suppressLocalAudioPlayback === true &&
    Reflect.has(window, 'CaptureController') &&
    Reflect.has(window, 'URLPattern'),
  AutoPip:
    navigator.mediaSession &&
    typeof navigator.mediaSession.setCameraActive === 'function' &&
    minimumChromiumVersion(124) &&
    !FeatureDetector.isPhone(),
};

export default supports;
